/* eslint-disable react/jsx-props-no-spreading */
import type { AppProps } from 'next/app';
import { Open_Sans } from 'next/font/google';
import 'normalize.css';
import '../styles/global.css';

const openSans = Open_Sans({
  subsets: ['latin'],
});

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <main className={openSans.className}>
      <Component {...pageProps} />
    </main>
  );
}
